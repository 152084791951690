import * as React from 'react';

import Layout from '../components/Layout';
import PostList from '../components/PostList';

export default ({ pageContext }) => (
  <Layout>
    <PostList {...pageContext} />
  </Layout>
);
