import { Link } from 'gatsby';
import * as React from 'react';

import Tags from '../Tags';

interface Props {
  title: string;
  posts: Array<{
    fields: {
      slug: string;
    },
    frontmatter: {
      title: string;
      tags: string[];
    },
  }>;
}

const PostList = ({ posts, title }: Props) => (
  <div>
    <h2>{title}</h2>
    <ul>
      {posts.map(({ fields, frontmatter }, i) => (
        <li key={`post-${i}`}>
          <Link to={fields.slug}>{frontmatter.title}</Link>
          <Tags tags={frontmatter.tags} />
        </li>
      ))}
    </ul>
  </div>
);

export default PostList;
